import React from 'react';

const IconEthereum = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M18.884,14.18a.5.5,0,0,0-.608-.127L12.5,16.941,6.724,14.053A.5.5,0,0,0,6.1,14.8l6,8c.006.008.016.011.023.019a.48.48,0,0,0,.117.1.392.392,0,0,0,.05.033.466.466,0,0,0,.42,0,.392.392,0,0,0,.05-.033.48.48,0,0,0,.117-.1c.007-.008.017-.011.023-.019l6-8A.5.5,0,0,0,18.884,14.18ZM12,17.809V21L8.171,15.9ZM13,21V17.809L16.829,15.9Z" />
    <path d="M6.014,12.558a.453.453,0,0,0,.006.082c.005.016.017.028.023.043a.306.306,0,0,0,.01.041c.006.013.021.018.028.03a.489.489,0,0,0,.13.142c.017.012.029.026.046.036s.012.012.019.015l6,3,.014,0a.475.475,0,0,0,.42,0l.014,0,6-3a.435.435,0,0,0,.042-.033.473.473,0,0,0,.086-.069.467.467,0,0,0,.059-.074.532.532,0,0,0,.036-.047c.007-.015.006-.03.012-.044a.384.384,0,0,0,.021-.04.327.327,0,0,0,0-.054.417.417,0,0,0,.008-.105.5.5,0,0,0-.011-.087.474.474,0,0,0-.036-.1.371.371,0,0,0-.016-.047l-6-10c-.007-.011-.02-.015-.027-.025a.48.48,0,0,0-.8,0c-.007.01-.02.014-.027.025l-6,10h0v0a.5.5,0,0,0-.064.232c0,.009-.007.016-.007.025A.443.443,0,0,0,6.014,12.558ZM12,14.691,7.618,12.5,12,10.309Zm1-4.382L17.382,12.5,13,14.691Zm0-1.118V4.305l4.187,6.979Zm-1,0L7.812,11.285,12,4.305Z" />
  </svg>
);

export default IconEthereum;
